html, .App {
  background-color: white;
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.App {
  padding-bottom: 2em;
}

#logo {
  font-size: 5em;
  font-weight: normal;
  text-align: center;
  color: #282c34;
  margin-bottom: .2em;
}

.cursive {
  font-family: 'Dawning of a New Day', cursive;
}

#menu #menu-toggle{
  border-radius: 0;
  border: none;
  background: #eee;
  color: #282c34;
  margin-top: 2em;
}

#menu .card-body {
  text-align: center;
  border-radius: 0;
  background: #eee;
}

#Content .card-body {
  background: #f1f0f0;
  box-shadow:
  0  5px 10px rgba(154,160,185,0.05),
  0 15px 40px rgba(166,173,201,0.2);

}

.card-body button {
  width: 6em;
  color: #282c34;
}

button#menu-toggle:focus { box-shadow: none;}

#menu button{
  margin-right: 2em;
  margin-top: .5em;
  text-transform: uppercase;
}

#menu .card .card-body button {
  width: 100%;
}

#footer {
  text-align: center;
  padding: 1em 0;
  width: 100%;
  color: #676869;
  font-size: .7em;
}

#Content .card {
  margin-bottom: 2em;
}

@media only screen and (max-width: 767px) {
  #logo {
    font-size: 3em;
    color: #282c34;
    margin-top: .4em;
    margin-left: .8em;
    text-align: center;
  }
}

#menu-container {
  background-color: #eee;
  margin-bottom: 1em;
}

#menu-container button{
  border: none;
  color: #282c34;
}

#menu-container button:hover{
  background: #eee;
  font-weight: bold;
}

button.btn.btn-outline-secondary.btn-md.btn-block {
  width: 100%;
}

#menu-container .card-body {
  background: #eee;
}

#top-bar{
  background-color: #282c34;
  height: 1.5em;
}

/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
    .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, 
    .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, 
    .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    .hidden-lg-down, .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
    {
        display: none !important;
    } 
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
    .hidden-xl-down, 
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, 
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
    {
        display: none !important;
    } 
}